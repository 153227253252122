@use 'variables' as var;

.buttons {
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: stretch;
  margin-block-start: var.$vertical-gutter;
  width: 100%;

  /*
   * As equal width columns in a fullwidth grid
   * https://moderncss.dev/solutions-to-replace-the-12-column-grid/
   */
  &--grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  /* Each btn fits its content */
  &--inline {
    align-items: center;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: flex-start;
  }
}

// The "!important"'s are there to override a tag's default styling
.btn,
.button {
  align-items: center;
  background: var.$clr-white;
  border: 1.5px solid transparent;
  border-radius: var.$button-border-radius;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.06);
  color: var.$clr-ff-text;
  cursor: pointer;
  display: inline-flex;
  font-size: 19px;
  font-weight: 600;
  justify-content: center;
  outline: none;
  padding-block: 11px;
  padding-inline: 17px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  user-select: none;
  width: 100%;

  &:active,
  &.active {
    margin-bottom: -1px;
    margin-top: 1px;
  }

  &:focus-visible {
    outline-offset: 1px;
  }

  &.fullwidth,
  &--fullwidth {
    display: flex;
    height: 100%;
    margin: auto;
    width: auto;
  }

  &.inline,
  &--inline {
    display: inline-flex;
    font-size: 16px;
    height: 100%;
    width: auto;
  }

  &.padded,
  &--padded {
    padding: 11px 17px;
  }

  &.padless,
  &--padless {
    padding: 0;
  }

  &.standalone,
  &--standalone {
    display: flex;
    height: 100%;
    margin-block-start: var.$vertical-gutter;
    min-height: 57px;
  }

  &.has-label > .icon,
  > .icon.has-label {
    margin-right: 0.4rem;
  }

  &.block,
  &--block {
    width: 100%;
  }

  /*
   * Color variants
   */
  &.beige,
  &--beige {
    background: var.$clr-carrara;
    border-bottom-color: darken(var.$clr-carrara, 5%);
    color: var.$clr-mine-shaft;

    &:hover {
      background: darken(var.$clr-carrara, 10%);
      border-bottom-color: darken(var.$clr-carrara, 15%);
    }

    &:focus-visible {
      border-color: var.$clr-silver;
      box-shadow: 0 0 0 3px transparentize(var.$clr-silver, 0.5);
    }
  }

  &.blue,
  &--blue {
    background: var.$clr-ff-teal;
    border-bottom-color: darken(var.$clr-ff-teal, 5%);
    color: var.$clr-white;

    &:hover {
      background-color: var.$clr-ff-teal-shade;
      border-bottom-color: var.$clr-ff-teal-shade;
    }

    &:focus-visible {
      border-color: darken(var.$clr-ff-teal, 5%);
      box-shadow: 0 0 0 3px transparentize(var.$clr-ff-teal, 0.5);
    }
  }

  &.gray,
  &--gray {
    background: var.$clr-pampas;
    border-color: var.$clr-westar;
    color: var.$clr-tuatara;

    &:hover {
      background-color: var.$clr-desert-storm;
    }

    &:focus-visible {
      border-color: var.$clr-nickel;
      box-shadow: 0 0 0 3px transparentize(var.$clr-nickel, 0.5);
    }
  }

  &.green,
  &--green {
    background: var.$clr-ff-green;
    border-bottom-color: darken(var.$clr-ff-green, 5%);
    color: var.$clr-white;

    &:hover {
      background-color: darken(var.$clr-ff-green, 10%);
      border-bottom-color: darken(var.$clr-ff-green, 12%);
    }

    &:focus-visible {
      border-color: darken(var.$clr-ff-green, 12%);
      box-shadow: 0 0 0 3px transparentize(var.$clr-ff-green, 0.5);
    }
  }

  &.plain,
  &--plain,
  &.sand,
  &--sand {
    background-color: var.$clr-sand;
    box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.1);
    color: var.$clr-text;

    &:hover {
      background-color: var.$clr-sand-80;
    }
  }

  &.primary,
  &--primary,
  &.red,
  &--red {
    background: var.$clr-ff-red;
    border-bottom-color: darken(var.$clr-ff-red, 7%);
    color: var.$clr-white;

    &:hover {
      background: var.$clr-ff-red-hover;
      border-bottom-color: var.$clr-ff-red-hover;
    }

    &:focus-visible {
      border-color: var.$clr-ff-red-hover;
      box-shadow: 0 0 0 3px transparentize(var.$clr-ff-red, 0.6);
    }
  }

  &.secondary,
  &--secondary {
    background-color: var.$clr-tide;
    box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.1);
    color: var.$clr-white !important;

    &:hover {
      background: darken(var.$clr-tide, 5%);
    }
  }

  &.transparent,
  &--transparent {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: var.$clr-ff-link;

    &:hover {
      background: transparent;
      border-color: transparent;
      color: var.$clr-mine-shaft;
    }

    &:focus-visible {
      @include outline;
    }
  }

  &.white,
  &--white {
    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }

    &:focus-visible {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    }
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    cursor: not-allowed;

    &:not(.processing),
    &:not(.processing) {
      background: var.$clr-nickel;
      border-color: var.$clr-nickel;
      color: var.$clr-white;

      &:focus-visible {
        box-shadow: 0 0 0 3px transparentize(var.$clr-dawn, 0.5);
      }
    }
  }

  > app-ff-loading-indicator,
  > app-svg,
  > svg-icon {
    display: inline-flex;

    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }

  /**
   * Sizes
   */
  &.micro,
  &--micro {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 6px 12px;
  }

  &.mini,
  &--mini,
  &.small,
  &--small {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 8px 18px;
  }

  &.medium,
  &--medium {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 18px;
  }

  &.large,
  &--large {
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    padding: 15px 18px;
  }
}

/**
 * Links we want to look like buttons
 */
a.btn,
a.button {
  @extend .btn--red;

  &:not(.white):link,
  &:not(.white):visited,
  &:not(.white):hover,
  &:not(.white):active {
    color: var.$clr-white !important;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none !important;

    &:focus-visible {
      outline: none !important;
    }
  }
}
