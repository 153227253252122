@use 'variables' as var;

/**
 * Defaults for most modals
 */
@mixin modal-defaults {
  .modal__body {
    font-size: 20px;
    padding: 0;
  }

  .modal__footer {
    align-items: center;
    border: none;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: 1em 0 0;
    min-width: 50px;

    .buttons__button {
      border-radius: var.$default-border-radius;
      overflow: hidden;
    }

    @media (min-width: var.$breakpoint-pad-min-width) {
      align-items: stretch;
      flex-flow: row wrap;
      justify-content: space-evenly;

      .buttons__button {
        flex: 1 0 50px;
        margin: 0 3px;
        max-width: 40%;
        min-height: 42px;
      }
    }
  }
}

/**
 * Style of outline (for focused elements)
 */
@mixin outline() {
  outline: var.$clr-ff-teal auto 1px;
  outline-offset: 3px;
}

/**
 * Overlay covers entire viewport
 */
@mixin overlay {
  & {
    background: transparent;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
  }
}

@mixin card {
  & {
    background-color: var.$clr-sand-60;
    padding: 1rem;
    border-radius: var.$default-border-radius;
    border: 1px solid var.$clr-border-100;
    color: var.$clr-text-100;

    @media (min-width: var.$breakpoint-pad-min-width) {
      padding: 1.5rem;
    }
  }
}

@mixin card-list-item {
  & {
    padding: 12px 22px;
    background-color: var.$clr-sand-50;
    border-radius: 8px;
    border: 1px solid var.$clr-border;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04);
  }
}

/**
 * The "skip to main content"-link for keyboard users
 */
.skiplink {
  position: absolute;
  left: -200vw;
  top: 6px;

  &:focus {
    left: var.$site-padding-horizontal-mob;

    @media (min-width: var.$breakpoint-pc-min-width) {
      left: var.$site-padding-horizontal-pc;
    }
  }
}

/**
 * Spinner clockwise
 */
@keyframes spinning {
  to {
    transform: rotate(360deg);
  }
}

/**
 * Spinner counter clockwise
 */
@keyframes spinningCounterClockwise {
  to {
    transform: rotate(-360deg);
  }
}

/**
 * Any content hidden from sight but available for accessibility tools such as screen readers
 */
.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
