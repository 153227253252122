@import 'variables';

@mixin svg-colors($property: 'stroke') {
  &-border {
    #{$property}: $clr-border;

    &-150 {
      #{$property}: $clr-border-150;
    }

    &-120 {
      #{$property}: $clr-border-120;
    }

    &-100 {
      #{$property}: $clr-border-100;
    }

    &-70 {
      #{$property}: $clr-border-70;
    }
  }

  &-none {
    #{$property}: none;
  }

  &-red {
    #{$property}: $clr-ff-red;

    &-100 {
      #{$property}: $clr-red-100;
    }

    &-70 {
      #{$property}: $clr-red-70;
    }

    &-50 {
      #{$property}: $clr-red-50;
    }

    &-20 {
      #{$property}: $clr-red-20;
    }
  }

  &-sand {
    #{$property}: $clr-sand;

    &-100 {
      #{$property}: $clr-sand;
    }

    &-70 {
      #{$property}: $clr-sand-70;
    }

    &-60 {
      #{$property}: $clr-sand-60;
    }

    &-50 {
      #{$property}: $clr-sand-50;
    }
  }

  &-text {
    #{$property}: $clr-text;

    &-100 {
      #{$property}: $clr-text-100;
    }

    &-70 {
      #{$property}: $clr-text-70;
    }

    &-60 {
      #{$property}: $clr-text-60;
    }

    &-50 {
      #{$property}: $clr-text-50;
    }

    &-30 {
      #{$property}: $clr-text-30;
    }
  }

  &-transparent {
    #{$property}: transparent;
  }

  &-white {
    #{$property}: $clr-white;
  }
}

svg-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  // Svg defaults
  svg {
    fill: none;
    font-size: 1px;
    height: 28px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 1.5;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out, fill 0.15s ease-in-out;
    width: 28px;

    .fill {
      @include svg-colors('fill');
    }

    .stroke {
      @include svg-colors('stroke');
    }

    .no {
      &-fill {
        fill: none;
      }

      &-stroke {
        stroke: none;
      }
    }
  }
  // End svg defaults

  // Sizes
  &.h36 svg {
    height: 36px;
  }

  &.s {
    &12 svg {
      height: 12px;
      width: 12px;
    }

    &16 svg {
      height: 16px;
      width: 16px;
    }

    &18 svg {
      height: 18px;
      width: 18px;
    }

    &20 svg {
      height: 20px;
      width: 20px;
    }

    &22 svg {
      height: 22px;
      width: 22px;
    }

    &24 svg {
      height: 24px;
      width: 24px;
    }

    &28 svg {
      height: 28px;
      width: 28px;
    }

    &48 svg {
      height: 48px;
      width: 48px;
    }

    &100 svg {
      height: 100px;
      width: 100px;
    }

    &120 svg {
      height: 120px;
      width: 120px;
    }
  }

  &.w196 svg {
    width: 196px;
  }
  // End sizes

  // Rotation
  &.rotate {
    &--180 {
      rotate: 180deg;
    }
  }

  // Monochrome
  &.monochrome {
    &--dark,
    &.stroke-text {
      *:not(.stroke-none):not(.stroke-transparent) {
        stroke: $clr-text;
      }
    }

    &.stroke-text-50 {
      *:not(.stroke-none):not(.stroke-transparent) {
        stroke: $clr-text-50;
      }
    }

    &--white {
      *:not(.stroke-none):not(.stroke-transparent) {
        stroke: $clr-white;
      }
    }
  }
  // End monochrome

  // Active
  &.active {
    svg {
      .active--fill {
        @include svg-colors('fill');
      }

      .active--stroke {
        @include svg-colors('stroke');
      }
    }
  }
  // End active

  // Disabled
  &.disabled {
    svg {
      .disabled--fill {
        @include svg-colors('fill');
      }

      .disabled--stroke {
        @include svg-colors('stroke');
      }
    }
  }
  // End disabled

  // Badge (small icon overlaying main icon)
  &.badge {
    svg {
      .badge--fill {
        @include svg-colors('fill');
      }

      .badge--stroke {
        @include svg-colors('stroke');
      }
    }
  }
  // End badge

  // Colored
  &.colored {
    svg {
      .colored--fill {
        @include svg-colors('fill');
      }

      .colored--stroke {
        @include svg-colors('stroke');
      }
    }
  }
  // End colored
}

// Transitions
svg-icon,
svg-icon > svg,
svg-icon > svg * {
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out, fill 0.15s ease-in-out;
}
