@use 'variables' as var;

/**
 * Area with sunken/inset look
 */
.well {
  background-color: var.$clr-desert-storm;
  border: 1px solid var.$clr-border;
  border-radius: var.$default-border-radius;
  display: block;
  margin-bottom: var.$default-vertical-gutter;
  padding: 0.5em;

  &__header {
    background: var.$clr-pampas;
    border-radius: var.$default-border-radius;
    cursor: not-allowed;
    padding: 0.5em;
    position: relative;

    &__indicator {
      position: absolute;
      right: 9px;
      top: 8px;
    }
  }

  &__footer,
  .footer {
    align-items: center;
    color: rgba(47, 47, 47, 0.7);
    display: flex;
    font-size: 15px;
    margin: 0.5em 0 0;
    min-height: 1.8em;
    padding: 0;

    &__actions {
      margin-left: auto;
      padding-left: 1em;
    }

    &__icon {
      margin: 1px 0.4em 0 0;
    }

    &__errors {
      color: var.$clr-ff-red;
    }

    &__text {
      display: block;

      > * {
        display: inline;
      }
    }

    &__tuple {
      align-items: flex-start;
      display: flex;
    }

    @media (min-width: var.$breakpoint-pad-min-width) {
      font-size: 95%;
    }
  }

  &__separator {
    border: none;
    border-top: 1px solid var.$clr-border;
    flex: 1 0 100%;
    margin: 1em 0 0.5em;
  }

  &--info {
    align-items: flex-start;
    border-color: var.$clr-nickel;
    display: flex;
    font-size: 0.95em;
    padding: 1em 0.75em;

    @media (min-width: var.$breakpoint-pad-min-width) {
      font-size: inherit;
    }

    .icon {
      flex: 0 1 50px;
      justify-self: center;
      margin: 0 22px 0 6px;
    }
  }

  &--form-group {
    input:not([type='checkbox']):not([type='radio']),
    select,
    textarea,
    .input {
      border-width: 1px;
    }
  }

  &--raised {
    background: var.$clr-white;
    border-radius: 10px;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.07);
  }

  &--notice {
    align-items: flex-start;
    border-color: var.$clr-ff-red;
    display: flex;
    font-size: 0.95em;
    padding: 1.25em 1em;

    @media (min-width: var.$breakpoint-pad-min-width) {
      font-size: inherit;
    }

    .icon {
      flex: 0 1 50px;
      justify-self: center;
      margin: 0 22px 0 6px;
    }
  }

  &--padded {
    padding: 1em 0.9em;
  }
}
