@import 'variables';
@import 'typography';

$clr-placeholder: $clr-chalice;
$clr-label: $clr-ff-text;
$font-size-helper: 18px;
$font-size-input: 19px;
$font-size-label: 19px;

@mixin input-box {
  background-color: $clr-white;
  border-width: 1px;
  border-color: $clr-border-120;
  border-radius: $default-border-radius;
  border-style: solid;
  box-sizing: border-box;
  font-size: $font-size-input;
  padding: 10px;
  width: 100%;

  &.secondary {
    background-color: transparent;
    cursor: pointer;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    opacity: 1;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: $clr-placeholder;
  }
}

.form {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;

  .form-group,
  .form-array {
    align-items: flex-start;
    display: flex;
    flex: 1 1 100%;
    flex-flow: row wrap;
    grid-column-gap: 0.5em; /* Will be used by browsers that do not support `column-gap` */
    column-gap: 0.5em; /* Used by browsers that support `column-gap` */
    grid-row-gap: 0.5em; /* Will be used by browsers that do not support `row-gap` */
    row-gap: 0.5em; /* Used by browsers that support `row-gap` */

    &--dual {
      @media (min-width: $breakpoint-pad-min-width) {
        flex: 1 1 40%;
      }
    }

    .form-control {
      flex: 1 1 100%;
      transition-property: box-shadow, border-color;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;

      &--dual {
        flex: 1 1 100%;

        @media (min-width: $breakpoint-pad-min-width) {
          flex: 1 1 40%;
          gap: 0.75em;
        }
      }

      .input-group {
        &__item:not(:first-child) {
          margin-top: $default-vertical-gutter;
        }
      }

      .radio-group {
        label {
          font-weight: normal;
          cursor: pointer;
        }
      }

      .helper-text {
        color: $clr-text-70;
        font-size: $font-size-helper;
        margin: 0.3em 0 0;
      }

      label + .helper-text {
        margin: 0 0 0.4em;
      }
    }

    + .form-group {
      .form-control {
        margin: $default-vertical-gutter 0 0;
      }
    }
  }

  /* No spacing on top */
  form > .form-group:first-of-type > .form-control {
    margin-top: 0;
  }

  label {
    color: $clr-label;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-label;
    font-weight: 600;
    letter-spacing: 0.1px;
    margin: 0 0 0.3em;
  }

  textarea {
    resize: none;
    min-height: 100px;
  }

  input:not([type='checkbox']):not([type='radio']),
  select,
  textarea,
  .input {
    display: block;

    @include input-box();
  }

  input,
  select,
  textarea {
    &:focus {
      border-color: #979a9a !important;
      box-shadow: 0 0 0 3px rgba(151, 151, 154, 0.18);
    }
  }

  input,
  select,
  app-phone-form,
  textarea {
    &:disabled,
    &[disabled] {
      background: $clr-pampas !important;
      cursor: not-allowed !important;
    }
  }

  input,
  textarea {
    &:read-only,
    &[readonly] {
      background: $clr-alabaster !important;
      cursor: pointer !important;
    }
  }

  /* Separate rule for select, some browsers deem selects to always be :read-only */
  select[readonly],
  select.readonly {
    background: $clr-alabaster !important;
    cursor: pointer !important;
  }

  input[type='checkbox'],
  input[type='radio'] {
    &:hover {
      cursor: pointer;
    }
  }

  &.submitted {
    input:not([type='checkbox']):not([type='radio']),
    select,
    textarea {
      &.ng-invalid {
        border-color: $clr-ff-red;

        &:focus {
          box-shadow: 0 0 0 2px transparentize($clr-ff-red, 0.6);
        }
      }
    }

    input[type='radio'].ng-invalid:after {
      border: 1px solid $clr-ff-red;
      border-radius: 4px;
      content: '';
      display: block;
      height: inherit;
      margin: -3px 0 0 -3px;
      padding: 2px;
      width: inherit;
    }
  }
}

.custom {
  // https://designtheway.com/custom-radio-button-and-checkbox/
  // https://codepen.io/anandaprojapati/pen/bWwRLO
  input[type='checkbox'],
  input[type='radio'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
