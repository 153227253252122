/* You can add global styles to this file, and also import other style files */
@use '@fagforbundet/ngx-components' as ff;

/**
 * Defaults for body
 */
body {
  &.no-scroll {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
}

/**
 * Import defaults for content (which might override body default)
 */
@import 'utilities';
@import 'typography';
@import 'highlighting';
@import 'buttons';
@import 'form';
@import 'icons';
@import 'scrollbars';

@include ff.reset();
@include ff.base();

ul {
  list-style-type: none;
}
